import axios from 'axios';
import { store } from '../redux/store';
import { type AppState } from '../redux/rootReducer';
import { COMMON_HEADERS } from '../constants';
import { apiUrls } from './apiUrls';
import { getBaseUrl } from '../services/cancelMemberShip';

export const axiosApiInstance = axios.create({
  baseURL: apiUrls.BASE_URL,
  timeout: 90000
});

export const getXpiKeyFromCountryCode = (countryCode: string) => {
  switch (countryCode) {
    case 'IN':
      return process.env.REACT_APP_IN_XAPI_KEY;
    case 'PK':
      return process.env.REACT_APP_PK_XAPI_KEY;
    case 'ID':
      return process.env.REACT_APP_ID_XAPI_KEY;
    case 'BD':
      return process.env.REACT_APP_BD_XAPI_KEY;
    case 'TH':
      return process.env.REACT_APP_TH_XAPI_KEY;
    case 'MY':
      return process.env.REACT_APP_MY_XAPI_KEY;
    case 'VN':
      return process.env.REACT_APP_VN_XAPI_KEY;
    case 'PH':
      return process.env.REACT_APP_PH_XAPI_KEY;
    default :
      return process.env.REACT_APP_IN_XAPI_KEY;
  }
};

export const getLangFromCountryCode = (countryCode: string) => {
  switch (countryCode) {
    case 'IN':
      return process.env.REACT_APP_IN_LANG;
    case 'PK':
      return process.env.REACT_APP_PK_LANG;
    case 'ID':
      return process.env.REACT_APP_ID_LANG;
    case 'BD':
      return process.env.REACT_APP_BD_LANG;
    case 'TH':
      return process.env.REACT_APP_TH_LANG;
    case 'MY':
      return process.env.REACT_APP_MY_LANG;
    case 'VN':
      return process.env.REACT_APP_VN_LANG;
    case 'PH':
      return process.env.REACT_APP_PH_LANG;
    default :
      return process.env.REACT_APP_IN_LANG;
  }
};

export const getHeadersDetails = () => {
  const { cancelMemberShip } = store.getState() as AppState;
  return {
    token: cancelMemberShip.token,
    languageCode: COMMON_HEADERS.defaultLanguageCode,
    countryCode: cancelMemberShip.countryCode
  };
};
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config: any) => {
    console.log('Api config', JSON.stringify(config));
    const { countryCode, token } = getHeadersDetails();
    const cancelMembershipUrl: string = `${getBaseUrl()}${apiUrls.CANCEL_MEMBERSHIP_OF_USER_URL}`;
    config.headers = {
      // authorization: token?.length > 0 ? token : '',
      'x-api-key': getXpiKeyFromCountryCode(countryCode),
      'country-code': countryCode,
      'language-code': getLangFromCountryCode(countryCode),
      Accept: '*/*',
      'Content-Type': 'application/json',
      ...config.headers
    };
    if (config.url === cancelMembershipUrl) {
      config.headers.authorization = token?.length > 0 ? token : '';
      delete config.headers['x-api-key'];
    } else {
      delete config.authorization;
    }
    if (countryCode === 'IN') {
      delete config.headers['x-api-key'];
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    // return await Promise.reject(error.response.data);
    return await Promise.reject(error.response.data || error);
  }
);
